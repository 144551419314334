import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import RegistrationForm from "./RegistrationForm";
import ConfirmPage from './ConfirmPage';
import CancelPage from './CancelPage';
import CancelCompletePage from './CancelCompletePage';
import DashboardPage from './DashboardPage';
import DashboardDetailPage from './DashboardDetailPage';
import LoginPage from './LoginPage';
import RegisterPage from './RegisterPage';
import PasswordPage from './PasswordPage';


import RegistrationFormCentralWorldCDS from "./RegistrationFormCentralWorldCDS";
import ConfirmPageCentralWorldCDS from './ConfirmPageCentralWorldCDS';
import CancelPageCentralWorldCDS from './CancelPageCentralWorldCDS';
import CancelCompletePageCentralWorldCDS from './CancelCompletePageCentralWorldCDS';

const App = () => {
    const [checkScreen, setCheckScreen] = useState(false);

    const [isAuthenticated, setIsAuthenticated] = useState(() => {
        return localStorage.getItem('isAuthenticated') === 'true';
    });

    useEffect(() => {
        if (!isAuthenticated) {
            localStorage.removeItem('isAuthenticated');
            localStorage.removeItem('token'); // ลบ JWT token ออกจาก localStorage
        }
    }, [isAuthenticated]);

    const handleLogout = () => {
        setIsAuthenticated(false);
        localStorage.removeItem('isAuthenticated');
        localStorage.removeItem('token'); // ลบ JWT token ออกจาก localStorage
    };


    const handlePasswordSubmit = (inputPassword) => {
        const correctPassword = 'MXCKWGCX'; // Set your desired password here
        if (inputPassword === correctPassword) {
          setCheckScreen(true);
        } else {
          alert('Incorrect password!');
        }
      };
    
    return (
        <Router>
            <div>
            {/* {checkScreen ? ( */}
                <Routes>
                    {/* Public Routes */}
                    <Route path="DiorBallOfDreams/IconSiam/VZ" element={<PasswordPage onPasswordSubmit={handlePasswordSubmit} />} />
                    <Route path="DiorBallOfDreams/IconSiam/VZ/registration" element={<PasswordPage onPasswordSubmit={handlePasswordSubmit} />} />
                    <Route path="DiorBallOfDreams/IconSiam/VZ/confirmation" element={<PasswordPage onPasswordSubmit={handlePasswordSubmit} />} />
                    <Route path="DiorBallOfDreams/IconSiam/VZ/cancellation" element={<PasswordPage onPasswordSubmit={handlePasswordSubmit} />} />
                    <Route path="DiorBallOfDreams/IconSiam/VZ/cancelComplete" element={<PasswordPage onPasswordSubmit={handlePasswordSubmit} />} />
                    {/* <Route path="DiorBallOfDreams/IconSiam/VZ" element={<RegistrationForm />} />
                    <Route path="DiorBallOfDreams/IconSiam/VZ/registration" element={<RegistrationForm />} />
                    <Route path="DiorBallOfDreams/IconSiam/VZ/confirmation" element={<ConfirmPage />} />
                    <Route path="DiorBallOfDreams/IconSiam/VZ/cancellation" element={<CancelPage />} />
                    <Route path="DiorBallOfDreams/IconSiam/VZ/cancelComplete" element={<CancelCompletePage />} /> */}

                    <Route path="DiorBallOfDreams/CentralWorld/CDS" element={<RegistrationFormCentralWorldCDS />} />
                    <Route path="DiorBallOfDreams/CentralWorld/CDS/registration" element={<RegistrationFormCentralWorldCDS />} />
                    <Route path="DiorBallOfDreams/CentralWorld/CDS/confirmation" element={<ConfirmPageCentralWorldCDS />} />
                    <Route path="DiorBallOfDreams/CentralWorld/CDS/cancellation" element={<CancelPageCentralWorldCDS />} />
                    <Route path="DiorBallOfDreams/CentralWorld/CDS/cancelComplete" element={<CancelCompletePageCentralWorldCDS />} />
{/* 
                    <Route path="DiorBallOfDreams/CentralWorld/CU" element={<RegistrationForm />} />
                    <Route path="DiorBallOfDreams/CentralWorld/CU/registration" element={<RegistrationForm />} />
                    <Route path="DiorBallOfDreams/CentralWorld/CU/confirmation" element={<ConfirmPage />} />
                    <Route path="DiorBallOfDreams/CentralWorld/CU/cancellation" element={<CancelPage />} />
                    <Route path="DiorBallOfDreams/CentralWorld/CU/cancelComplete" element={<CancelCompletePage />} />

                    <Route path="DiorBallOfDreams/SiamParagon/TMG" element={<RegistrationForm />} />
                    <Route path="DiorBallOfDreams/SiamParagon/TMG/registration" element={<RegistrationForm />} />
                    <Route path="DiorBallOfDreams/SiamParagon/TMG/confirmation" element={<ConfirmPage />} />
                    <Route path="DiorBallOfDreams/SiamParagon/TMG/cancellation" element={<CancelPage />} />
                    <Route path="DiorBallOfDreams/SiamParagon/TMG/cancelComplete" element={<CancelCompletePage />} />

                    <Route path="DiorBallOfDreams/SiamParagon/CU" element={<RegistrationForm />} />
                    <Route path="DiorBallOfDreams/SiamParagon/CU/registration" element={<RegistrationForm />} />
                    <Route path="DiorBallOfDreams/SiamParagon/CU/confirmation" element={<ConfirmPage />} />
                    <Route path="DiorBallOfDreams/SiamParagon/CU/cancellation" element={<CancelPage />} />
                    <Route path="DiorBallOfDreams/SiamParagon/CU/cancelComplete" element={<CancelCompletePage />} /> */}


                    <Route 
                        path="/registerPage" 
                        element={isAuthenticated ? <RegisterPage /> : <Navigate to="/login" />} 
                    />
                    {/* Protected Routes */}
                    <Route path="/dashboard" 
                        element={isAuthenticated ? <DashboardPage handleLogout={handleLogout} /> : <LoginPage />} 
                    />
                    <Route 
                        path="/dashboardDetail/:campaign_id/:campaign_name" 
                        element={isAuthenticated ? <DashboardDetailPage /> : <Navigate to="/login" />} 
                    />

                    {/* Login Route */}
                    <Route 
                        path="/login" 
                        element={<LoginPage />
                        } 
                    />
                </Routes>

            {/* ) : (
                <PasswordPage onPasswordSubmit={handlePasswordSubmit} />
            )} */}
            </div>
        </Router>
    );
};

export default App;
import React, { useState } from 'react';
import eventImage from "./images/christian-dior-new3874.jpg"; // Adjust the path as necessary


const PasswordPage = ({ onPasswordSubmit }) => {
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onPasswordSubmit(password);
  };

  return (
    <div>
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '70vh',
      margin: 0
    }}>
          <img
            src={eventImage}
            alt="Event"
        style={{
          maxWidth: '70%',
          maxHeight: '100%',
        }}
          />
           <h1 style={{
            fontSize: '1.4rem',
            textAlign: 'center',
            fontFamily: 'Noto Sans Thai,sans-serif',

          }}>
            {/* Coming Soon... */}
            Thank you for your interest.
          </h1>
          <h1 style={{
            fontSize: '1.4rem',
            textAlign: 'center',
            fontFamily: 'Noto Sans Thai,sans-serif',
          }}>
            {/* Coming Soon... */}
            This campaign is now over.
          </h1>
      </div>
      {/* <h1 style={{
        fontSize: '2rem',
        textAlign: 'center'
      }}>
        Coming Soon...
      </h1> */}
      {/* <h1>Please Enter the Password</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter password"
        />
        <button type="submit">Submit</button>
      </form> */}
    </div>
  );
};

export default PasswordPage;
import React from "react";
import { Row, Col } from 'reactstrap';
import DataTable from 'react-data-table-component';
import moment from 'moment';

const TableDashboardCampignDeatail = ({ campaigns }) => {
  const columns = [
    {
      name: 'First Name',
      selector: row => row.first_name,
      sortable: true,
    },
    {
      name: 'Last Name',
      selector: row => row.last_name,
      sortable: true,
    },
    {
      name: 'Email',
      selector: row => row.email_address,
      sortable: true,
    },
    {
      name: 'Phone',
      selector: row => row.mobile_phone,
      sortable: true,
    },
    {
      name: 'Store',
      selector: row => row.store_name,
      sortable: true,
    },
    {
      name: 'Partner',
      selector: row => row.partner_name,
      sortable: true,
    },
    {
      name: 'Registration Date',
      selector: row => row.ts_created,
      sortable: true,
      sortFunction: (rowA, rowB) => {
        const dateA = moment(rowA.ts_created);
        const dateB = moment(rowB.ts_created);
        
        if (dateA.isBefore(dateB)) return -1;
        if (dateA.isAfter(dateB)) return 1;
        return 0;
      }
    },
    {
      name: 'Cancellation Date',
      selector: row => row.ts_canceled,
      sortable: true,
      sortFunction: (rowA, rowB) => {
        const dateA = moment(rowA.ts_canceled);
        const dateB = moment(rowB.ts_canceled);
        
        if (dateA.isBefore(dateB)) return -1;
        if (dateA.isAfter(dateB)) return 1;
        return 0;
      }
    },
  ];

  return (
    // <Row>
      <Col xs="12" sm="12" lg="12" className="box">
        <div className="box-content-tb">
          <DataTable
            columns={columns}
            data={campaigns}
            pagination
            paginationPerPage={5}
            paginationRowsPerPageOptions={[5, 10, 20, 30]}
            striped
            highlightOnHover
            noHeader
          />
        </div>
      </Col>
    // </Row>
  );
};

export default TableDashboardCampignDeatail;
import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation } from "react-router-dom";
import imgFrag from "./images/Frag.jpg"; // Adjust the path as necessary
import imgLips from "./images/Lips.jpg"; // Adjust the path as necessary
import imgSkincare from "./images/Skincare.jpg"; // Adjust the path as necessary
import eventImage from "./images/dior_logo_ex.png"; // Adjust the path as necessary

const ConfirmPageCentralWorldCDS = () => {
  const location = useLocation();
  const { value } = location.state || {};
  const [date, setDate] = React.useState("");

  useEffect(() => {
    // console.log(value);
    if (value) {
      const [year, month, day] = value.split('-');
      const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      
      // If day is less than 10, remove the leading zero
      const dayFormatted = parseInt(day, 10);
      
      const formattedDate = `${dayFormatted} ${months[parseInt(month) - 1]} ${year}`;
      setDate(formattedDate);
    }
  }, [value]);

  return (
    <div style={{paddingTop : "25px"}}> 
      <div className="container">
       
          <p style={{ textAlign: "center" }}>
            ขอบคุณสำหรับการลงทะเบียนเพื่อเข้าร่วมกิจกรรม <br></br>The Dior Ball of Dreams
            <br />
          </p>
          <br />
          <p style={{ textAlign: "center", fontWeight: "bold" }}>วันที่ {date}</p>
          <br />
          <p style={{ textAlign: "center" }}>
            ร่วมเฉลิมฉลองเทศกาลแห่งความสุขกับ The Dior Ball of Dreams ที่ได้รับแรงบันดาลใจจากกลาง
            พระราชวังแวร์ซายอันหรูหรา ค้นพบของขวัญสำหรับคนพิเศษ พร้อมกิจกรรม personalization กล่องของขวัญ
            การ์ดเพื่อคนที่คุณรัก
          </p>
          <p style={{ textAlign: "center" }}>
            พิเศษ รับผลิตภัณฑ์น้ำหอมทดลอง*
            <br></br>
            ระหว่างวันที่ 19 พ.ย. – 4 ธ.ค. 67
            <br></br>
            เวลา 11:00 - 20:00 น.
            <br></br>
            ณ ลาน Beacon 3 ชั้น 1 ศูนย์การค้าเซ็นทรัลเวิลด์
          </p>
          <p style={{ textAlign: "center" }}>
            รอรับข้อความ SMS
            และแสดงข้อความที่ได้รับที่จุดลงทะเบียนเพื่อรับสิทธิพิเศษภายในงาน{" "}
          </p>
          <p style={{ textAlign: "center" }}>
            *รับผลิตภัณฑ์ขนาดทดลองหลังรับบริการ 
            <br></br>จำนวนจำกัด 1 ท่าน : 1 สิทธิ์
          </p>
        </div>
        <div style={{display: "flex", maxWidth: "100%",justifyContent: "center"}}>
        <div style={{maxWidth: "215px"}}>
          <img
            src={imgFrag}
            alt="Event"
            // className="img-fluid mb-4"
            style={{ width: "100%", height: "auto", maxWidth: "none" }}
          />
        </div>
        <div style={{maxWidth: "215px"}}>
          <img
            src={imgLips}
            alt="Event"
            // className="img-fluid mb-4"
            style={{ width: "100%", height: "auto", maxWidth: "none" }}
          />
        </div><div style={{maxWidth: "215px"}}>
          <img
            src={imgSkincare}
            alt="Event"
            // className="img-fluid mb-4"
            style={{ width: "100%", height: "auto", maxWidth: "none" }}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmPageCentralWorldCDS;

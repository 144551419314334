import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Container,
} from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import eventImage from "./images/CoverPage1.jpg";

const CancelCompletePageCentralWorldCDS = () => {
  const location = useLocation();
  const { value } = location.state || {};
  const [store, setStore] = React.useState("");
  const navigate = useNavigate();

  const handleUpdate = () => {
    navigate(`/DiorBallOfDreams/CentralWorld/CDS/registration`);
  };

  useEffect(() => {
    if (value) {
      setStore(value);
    }
  }, []);

  return (
    <div>
      <div className="text-center">
        <img
          src={eventImage}
          alt="Event"
          // className="img-fluid mb-4"
          style={{ width: "100%", height: "auto", maxWidth: "700px" }}
        />
      </div>
    <Container className="mt-5" style={{ textAlign: "center" }}>
      คุณได้ยกเลิกกิจกรรม
      <br />
      The Dior Ball of Dreams
      ณ ลาน Beacon 3 ชั้น 1 ศูนย์การค้าเซ็นทรัลเวิลด์
      สำเร็จ
      <br />
      <br />
      <Button 
      onClick={handleUpdate}
        style={{
          width: "100%",
          maxWidth: "235px",
          backgroundColor: "#000",
          borderColor: "#000",
          color: "#FFF",
        borderRadius: "1px"
      }}
      > 
        ลงทะเบียน Register
      </Button>
    </Container>
    </div>
  );
};

export default CancelCompletePageCentralWorldCDS;
